import { useEffect } from 'react';
import clarity from '@microsoft/clarity';

export const trackUserAction = (action, value = '') => {
  if (window.clarity) {
    window.clarity("set", action, value);
  }
};

const ClarityProvider = ({ children }) => {
  useEffect(() => {
    if (typeof clarity.default === 'function') {
      clarity.default("q8pn9hjbym");
    }
  }, []);

  return children;
};

export default ClarityProvider;